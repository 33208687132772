body, html {
    font-family: helvetica, arial, sans-serif;
    margin: 1em;
    background-color: #ececec;
}

h1,
h2,
h4,
a {
    color: #6e6d6d;
}

.brand-main {
    background-color: #ececec;
    /* Main Background Color */
}

.brand {
    background-color: #ff7171;
    /* Header and Footer bar Color */
}

.card {
    background-color: #ececec;
}

.navbar .navbar-brand {
    color: #ececec;
    margin-bottom: 0px;
}

.card-header img {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.d-block {
    border-radius: 0.25rem;
}

.jumbotron{
    background: url("/img/masaimarasunset.jpg") no-repeat center center;
    background-size: cover;
    padding: 20% 2rem;
    min-width: 100vw;
}

.welcome-text {
    color:white;
    text-align: center;
    margin: auto;
    max-width: 40rem;
    font-size: 2.5rem;
}